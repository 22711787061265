import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function SpaceproService() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "spacePro-services" } }
                        page: { code: { _eq: "espacePro" } }
                    }
                ) {
                    text
                    id
                    file {
                        filename_disk
                    }
                }
            }
        }
    `);

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    return (
        <div className="tw-p-3 tw-w-full sm:tw-w-8/12">
            <div
                className="tw-p-5 tw-max-w-5xl"
                dangerouslySetInnerHTML={{ __html: content?.text }}
            ></div>
        </div>
    );
}
