import React from 'react';

import SpaceproMap from './authenticated/map';
import SpaceproGeocodage from './authenticated/geocodage';
import SpaceproVoieExport from './authenticated/voieexport';

import useWindowSize from '../../hooks/useWindowSize';


export default function SpaceProAuthenticated () {
    const [width, height] = useWindowSize(183);
    
    if (width == 0 || height == 0) return null;

    return (
        <div 
            className="tw-flex"
            style={{ height: `${height}px` }}
        >
            <div className="tw-h-full tw-w-2/3 spacepro">
                <SpaceproMap />
            </div>
            <div className="tw-h-full tw-w-1/3 tw-shadow-md">
                <div
                    className="tw-p-2"
                >
                    <SpaceproVoieExport />
                </div>
                <div>
                    <SpaceproGeocodage />
                </div>
            </div>
        </div>
    )
}