import React, { useEffect } from "react";

import { useSpaceproStore } from "../../../../../store/spacepro";


export default function BoundsControl () {
    const { mapBounds, mapObj } = useSpaceproStore() ;

    useEffect(() => {
        if ( ! (mapBounds && mapObj)) return ;

        mapObj.fitBounds(mapBounds, {
            padding: 25
        });

    }, [mapBounds, mapObj])

    return null;
}