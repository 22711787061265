import React from "react";

import { useSpaceproStore } from "../../../../../store/spacepro";
import { ID_LAYER_NUMERO_ADRESSE_SELECTION } from "../../../../configs/map/configs_constant";

export default function SelectionAddressControl() {
    const { mapObj, aSelectedAddresses } = useSpaceproStore();

    React.useEffect(() => {
        if (!mapObj) return;

        mapObj.setFilter(ID_LAYER_NUMERO_ADRESSE_SELECTION, [
            "in",
            "id_numero",
            ...aSelectedAddresses,
        ]);
    }, [aSelectedAddresses, mapObj]);

    return null;
}
