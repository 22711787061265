import {
    ID_LAYER_COMMUNE,
    ID_SOURCE_LAYER_COMMUNE,
    ID_LAYER_VOIE,
    ID_SOURCE_LAYER_VOIE,
    ID_LAYER_NUMERO_ADRESSE,
    ID_SOURCE_LAYER_NUMERO_ADRESSE,
    ID_LAYER_NUMERO_ADRESSE_LABEL,
    ID_SOURCE_LAYER_NUMERO_ADRESSE_LABEL,
    ID_LAYER_COMMUNE_LABEL,
    ID_SOURCE_LAYER_COMMUNE_LABEL,
    ID_LAYER_VOIE_SELECTION,
    ID_SOURCE_LAYER_VOIE_SELECTION,
    ID_LAYER_NUMERO_ADRESSE_SELECTION,
} from "../../../../configs/map/configs_constant";

export const LIST_SOURCE_LAYERS = [
    ID_SOURCE_LAYER_COMMUNE,
    ID_SOURCE_LAYER_VOIE,
    ID_SOURCE_LAYER_VOIE_SELECTION,
    ID_SOURCE_LAYER_NUMERO_ADRESSE,
    ID_SOURCE_LAYER_NUMERO_ADRESSE_LABEL,
    ID_SOURCE_LAYER_COMMUNE_LABEL,
];

export const LIST_LAYERS = [
    "layer_voie_decaration",
    ID_LAYER_VOIE,
    ID_LAYER_NUMERO_ADRESSE,
    ID_LAYER_COMMUNE,
    ID_LAYER_COMMUNE_LABEL,
    ID_LAYER_VOIE_SELECTION,
    "layer_voie_decaration_label",
    ID_LAYER_NUMERO_ADRESSE_LABEL,
    ID_LAYER_NUMERO_ADRESSE_SELECTION,
];

export const LIST_LAYERS_TO_MANAGED = {
    [ID_LAYER_COMMUNE]: {
        name: "Commune",
        opacityProperties: ["line"],
        children: [
            {
                id: ID_LAYER_COMMUNE_LABEL,
                opacityProperties: ["text"],
            },
        ],
    },
    [ID_LAYER_VOIE]: {
        name: "Voies",
        opacityProperties: ["line"],
        children: [
            {
                id: ID_LAYER_VOIE_SELECTION,
                opacityProperties: ["line"],
            },
            {
                id: "layer_voie_decaration",
                opacityProperties: ["line"],
            },
            {
                id: "layer_voie_decaration_label",
                opacityProperties: ["text"],
            },
            {
                id: "arrow",
                opacityProperties: ["icon"],
            },
        ],
    },
    [ID_LAYER_NUMERO_ADRESSE]: {
        name: "Adresses",
        opacityProperties: ["circle", "circle-stroke"],
        children: [
            {
                id: ID_LAYER_NUMERO_ADRESSE_LABEL,
                opacityProperties: ["text"],
            },
            {
                id: ID_LAYER_NUMERO_ADRESSE_SELECTION,
                opacityProperties: ["circle", "circle-stroke"],
            },
        ],
    },
};
