import React from "react";

import { useSpaceproStore } from "../../../../../store/spacepro";
import { ID_LAYER_VOIE_SELECTION } from "../../../../configs/map/configs_constant";

export default function SelectionVoieControl() {
    const { mapObj, aSelectedVoie } = useSpaceproStore();

    React.useEffect(() => {
        if (!mapObj) return;

        mapObj.setFilter(ID_LAYER_VOIE_SELECTION, [
            "in",
            "id_voie",
            ...aSelectedVoie
        ]);
    }, [aSelectedVoie, mapObj]);

    return null;
}
