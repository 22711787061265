import React from "react";

import SpaceProVisitor from "./visitor";
import SpaceProAuthenticated from "./authenticated";

import { useSpaceproStore } from "../../store/spacepro";
import { useUserStore } from "../../store/user";
import { isConnected } from "../../utils/utils";
import SpaceProUpload from "./uploadAuthenticated";

export default function SpacePro() {
    const { bShowViewExport, bShowViewUploadFile } = useSpaceproStore();
    const { bLoading: bLoadingUserInStore } = useUserStore();

    // if (bLoadingUserInStore) return <div className="tw-h-screen"></div>;

    return (
        <>
            {bShowViewExport && isConnected() ? (
                <SpaceProAuthenticated />
            ) : (
                <>
                    {bShowViewUploadFile && isConnected() ? (
                        <SpaceProUpload />
                     ) : (
                        <SpaceProVisitor />
                    )} 
                </>
            )}
        </>
    );
}
