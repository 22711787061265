import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Input, Textarea } from "react-daisyui";
import { toast } from "react-toastify";
import MultipleUploadFile from "../fileupload/multipleuploadfile";
import Map from "../services/address/map/map";
import { useAddressStore } from "../../store/address";
import useWindowSize from "../../hooks/useWindowSize";
import { useSpaceproStore } from "../../store/spacepro";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import { useUserStore } from "../../store/user";
import { saveFile } from "../../api/api";
import { useDropzone } from "react-dropzone";
import { ClipLoader } from "react-spinners";

interface stateInterface {
    id: number;
    comment: string;
    nom: string;
    prenom: string;
    email: string;
    file: any[];
}

const SpaceProUpload = () => {
    const stateUser = useUserStore.getState();
    const ref = useRef();
    const initialState = {
        id: stateUser?.oUser?.id || 0,
        comment: "",
        nom: stateUser?.oUser?.name || "",
        prenom: stateUser?.oUser?.username || "",
        email: stateUser?.oUser?.email || "",
        file: [],
    };

    const [state, setState] = useState<stateInterface>(initialState);
    const [nomOrg, setNomOrg] = useState("");

    const [formErrors, setFormErrors] = useState<stateInterface>(initialState);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [feature, setFeature] = useState<any>();
    const [resetFiles, setResetFiles] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState<boolean>(false); // New state for loader

    const { setShowViewUploadFile } = useSpaceproStore();
    const { file: fileStore } = useAddressStore();

    const { comment, nom, prenom, email, file, id } = state;

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "nom_org") {
            setNomOrg(value);
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChangeFile = async (e: any) => {
        const files = e.target.files;
        setState((prevState) => ({
            ...prevState,
            [name]: files,
        }));
    };

    const [width, height] = useWindowSize(70);

    if (width == 0 || height == 0) return null;

    const retourPage = (e: any) => {
        e.preventDefault();
        history.back();
    };

    const handleSave = (event: any) => {
        event.preventDefault();

        setIsUploading(true);
        const files: any[] = [];

        fileStore.length > 0
            ? fileStore.forEach((item: any) => {
                  files.push(item);
              })
            : console.log("No file");

        setFormErrors(validate(state));

        if (comment == "" || files.length <= 0 || nomOrg == "") {
            toast.error("Veuillez remplir tous les champs obligatoires", {
                position: "top-right",
                autoClose: 4000,
            });
            setIsUploading(false);
        } else {
            saveFile({
                id_utilisateur: state.id.toString(),
                file: files,
                comment: comment,
                organisme: nomOrg,
            })
                .then((res) => {
                    let data = res.data;
                    if (data.success) {
                        setState(initialState);
                        setNomOrg("");
                        setIsSubmit(true);
                        setResetFiles(true);

                        setTimeout(() => {
                            setResetFiles(false);
                        }, 1000);

                        toast.info(
                            "L'enregistrement a été effectué avec succès.",
                            {
                                position: "top-right",
                                autoClose: 4000,
                            }
                        );
                    } else {
                        toast.error("L'enregistrement n'a pas abouti !", {
                            position: "top-right",
                            autoClose: 4000,
                        });
                        console.log("No data found : " + data);
                    }
                    setIsUploading(false);
                })
                .catch((error: any) => {
                    console.log(error);
                    setIsUploading(false);
                });
        }
    };

    const validate = (values: any) => {
        const errors = initialState;
        if (!values.comment) {
            document.getElementById("comment")!.style.border = "1px solid red";
        }
        if (!nomOrg) {
            document.getElementById("nom_org")!.style.border = "1px solid red";
        }
        if (values.comment) {
            document.getElementById("comment")!.style.border =
                "1px solid #D5D5D6";
        }
        if (nomOrg) {
            document.getElementById("nom_org")!.style.border =
                "1px solid #D5D5D6";
        }

        return errors;
    };

    const divStyles = {
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        marginTop: "-6px",
        height: height / 1.15 + "px",
    };

    const style = {
        height: `${height}px`,
        width: "100%",
    };

    const onGoToSpaceProSection = () => {
        setShowViewUploadFile(false);
    };

    return (
        <div className="tw-flex tw-pt-5 tw-pb-10">
            <div className="tw-w-full">
                <div className="tw-grid tw-grid-cols-[minmax(0,1fr),auto,minmax(0,1fr)] tw-items-center">
                    <div></div>

                    <h3 className="tw-text-center tw-pb-2 tw-pt-2 tw-ml-7">
                        Dépôt de fichiers
                    </h3>
                    <button
                        aria-label="Page d'actualités"
                        className="tw-flex flex-inline-col  hover:tw-text-gray-500 tw-pb-2  tw-text-2xl tw-font-bold tw-ml-auto tw-mr-5"
                        onClick={onGoToSpaceProSection}
                    >
                        <ArrowCircleLeftIcon
                            className="tw-h-6 tw-w-6 tw-mr-2"
                            aria-hidden="true"
                        />
                        <span className="tw-underline">Retour</span>
                    </button>
                </div>
                <form onSubmit={handleSave} className="tw-grid tw-grid-cols-1">
                    <div className="tw-flex tw-flex-col tw-space-y-5 tw-items-center tw-ml-0 tw-mb-7 tw-px-7 lg:tw-px-0 lg:tw-mb-0 lg:tw-ml-7 ">
                        <label className="tw-w-full tw-max-w-xl tw-text-center tw-text-2xl tw-font-bold">
                            Vos informations
                        </label>
                        <div className="tw-w-full tw-max-w-xl">
                            <label className="tw-w-full tw-max-w-xl tw-text-sm tw-font-light tw-pb-2 tw-ml-2">
                                Nom :
                            </label>
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                placeholder="Nom"
                                onChange={handleChange}
                                value={nom}
                                name="nom"
                                id="nom"
                                disabled
                            />
                        </div>

                        <div className="tw-w-full tw-max-w-xl">
                            <label className="tw-w-full tw-max-w-xl tw-text-sm tw-font-light tw-pb-2 tw-ml-2">
                                Prénom :
                            </label>
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                placeholder="Prénom"
                                onChange={handleChange}
                                value={prenom}
                                name="prenom"
                                id="prenom"
                                disabled
                            />
                        </div>

                        <div className="tw-w-full tw-max-w-xl">
                            <label className="tw-w-full tw-max-w-xl tw-text-sm tw-font-light tw-pb-2 tw-ml-2">
                                E-mail :
                            </label>
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                placeholder="Email"
                                onChange={handleChange}
                                value={email}
                                name="email"
                                id="email"
                                disabled
                            />
                        </div>

                        <div className="tw-w-full tw-max-w-xl">
                            <label className="tw-w-full tw-max-w-xl tw-text-sm tw-font-light tw-pb-2 tw-ml-2">
                                Organisme :
                            </label>
                            <Input
                                className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                placeholder="Organisme"
                                onChange={handleChange}
                                value={nomOrg}
                                name="nom_org"
                                id="nom_org"
                            />
                        </div>

                        <div className="tw-w-full tw-max-w-xl">
                            <label className="tw-w-full tw-max-w-xl tw-text-sm tw-font-light tw-pb-2 tw-ml-2">
                                Commentaire :
                            </label>
                            <Textarea
                                className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF]"
                                placeholder="Commentaire"
                                rows={3}
                                onChange={handleChange}
                                value={comment}
                                name="comment"
                                id="comment"
                            />
                        </div>

                        <div className="tw-w-full tw-max-w-xl">
                            <label className="tw-w-full tw-max-w-xl tw-text-sm tw-font-light tw-pb-2 tw-ml-2">
                                Déposez vos fichiers :
                            </label>
                            <MultipleUploadFile
                                title="Déposez vos fichiers ici"
                                resetFiles={resetFiles}
                            />
                        </div>

                        <div className="tw-w-full tw-max-w-xl tw-text-center">
                            <button
                                type="submit"
                                className="tw-mt-5 tw-bg-[#2684FF] tw-text-white tw-font-medium tw-rounded-[4px] tw-h-[36px] tw-w-full tw-max-w-xl"
                                disabled={isUploading}
                            >
                                {isUploading ? (
                                    <ClipLoader size={24} color={"#fff"} />
                                ) : (
                                    "Envoyer"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SpaceProUpload;
