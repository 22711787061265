import React from "react";

import MapboxDraw from "@mapbox/mapbox-gl-draw";

import { LIST_LAYERS, LIST_SOURCE_LAYERS } from "./map/configs/layers";

import { useSpaceproStore } from "../../../store/spacepro";
import { createMap } from "../../configs/map/configs_utils";

import SelectionVoieControl from "./voieexport/controls/selectionVoie";
import SelectionAdressControl from "./voieexport/controls/selectionAddress";
import BoundsControl from "./voieexport/controls/bounds";

import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-basemaps/lib/basemaps.css";

export default function SpaceproMap() {
    const mapContainer = React.useRef<HTMLDivElement>(null);
    const { mapObj, setMapObj, resetStore } = useSpaceproStore();

    React.useEffect(() => {
        if (mapObj) return;

        const oMap = createMap(
            mapContainer.current as HTMLDivElement,
            LIST_SOURCE_LAYERS,
            LIST_LAYERS
        );

        var draw: any = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
            },
        });

        oMap.addControl(draw, "top-right");

        oMap.on("draw.create", function (e) {
            const data = draw.getAll();
            const stateSpacePro = useSpaceproStore.getState();
            stateSpacePro.setExportFilter({
                type: "geometry",
                filter: data.features[0].geometry.coordinates[0],
            });
        });

        oMap.on("draw.modechange", function (e) {
            const stateSpacePro = useSpaceproStore.getState();

            let cursor = "";
            if (e.mode == "draw_polygon") {
                cursor = "default";

                let data = draw.getAll();
                data.features?.map((feature: any) => {
                    let coordinates = feature.geometry.coordinates[0];
                    if (coordinates[0] != null) {
                        draw.delete(feature.id);
                    }
                });

                stateSpacePro.setExportFilter({
                    type: "geometry",
                    filter: null,
                });
            }

            oMap.getCanvas().style.cursor = cursor;
        });

        const tool = document.querySelector(
            ".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon"
        ) as HTMLElement | null;
        tool?.setAttribute("title", "Outil de sélection de voie");

        //@ts-ignore
        oMap._draw = draw;

        oMap.on("load", function () {
            if (mapObj) return;
            setMapObj(oMap);
        });

        return () => {
            resetStore();
        };
    }, []);

    return (
        <div ref={mapContainer} className="tw-h-full tw-w-full">
            <BoundsControl />
            <SelectionAdressControl />
            <SelectionVoieControl />
        </div>
    );
}
