import React from "react";

import { useSpaceproStore } from "../../../../store/spacepro";
import { getExportResume } from "../../../../api/api";
import { getItemSelect } from "../../../../api/api";

export default function VoieExportResume() {
    const {
        aExportResume,
        exportFilter,
        dataType,
        setSelectedVoie,
        setSelectedAddresses,
        setExportResume,
    } = useSpaceproStore();

    React.useEffect(() => {
        const { type, filter } = exportFilter;

        if (!(type && filter)) {
            setExportResume(null);
            setSelectedVoie([]);
            setSelectedAddresses([]);
            return;
        }

        let params: any;

        if (type == "geometry") {
            params = {
                geometry: filter,
            };
        } else {
            params = {
                id_localite: filter,
            };
        }

        getExportResume(params, dataType)
            .then((res) => {
                let data = res.data;
                if (data.success === false) {
                    data = null;
                }
                setExportResume(data);
            })
            .catch((error) => {
                console.log(error);
                setExportResume(null);
            });

        getItemSelect(params, dataType)
            .then((res) => {
                let data = res.data;
                if (data.success === false) {
                    data = null;
                }
                if (dataType == "voie") {
                    setSelectedVoie(data.map((dt: any) => dt.id_voie));
                    setSelectedAddresses([]);
                } else {
                    setSelectedAddresses(data.map((dt: any) => dt.id_numero));
                    setSelectedVoie([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [exportFilter, dataType]);

    return (
        <div className="tw-border tw-min-h-[150px] tw-max-h-[350px] tw-overflow-auto tw-my-4 tw-rounded-md tw-p-2">
            <h6>Données à exporter :</h6>
            <ul>
                {dataType == "voie" &&
                    aExportResume?.map((data: any, index) => (
                        <li key={index} className="tw-list-disc">
                            {data.typologie} : {data.total}
                        </li>
                    ))}
                {dataType == "adresse" &&
                    aExportResume?.map((data, index) => (
                        <li key={index} className="tw-list-disc">
                            Adresse : {data.total}
                        </li>
                    ))}
            </ul>
        </div>
    );
}
