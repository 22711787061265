import React from "react";

import axios from "axios";
import Select, { SingleValue } from "react-select";
import { useSpaceproStore } from "../../../../store/spacepro";
import {
    extentParamsInterface as zoomOptions,
    urlGetExtent,
    urlGetCommune,
    urlGetVoie,
    urlGetNumero,
    urlGetAddressById,
    findAddress,
} from "../../../../api/api";

interface ICommune {
    id_localite: number;
    nom: string;
}
interface IOptions {
    value: number;
    label: string;
    enceinte: string;
}
interface IState {
    optionsCommune: IOptions[] | [];
    bDisableComboCommune: boolean;
}
interface IProps {
    fileFormat: string;
    setFileFormat: Function;
}

export default function VoieExportForm({ fileFormat, setFileFormat }: IProps) {
    const {
        setExportFilter,
        setDataType,
        setMapBounds,
        dataType,
        exportFilter,
        mapObj,
    } = useSpaceproStore();

    const [state, setState] = React.useState<IState>({
        optionsCommune: [],
        bDisableComboCommune: true,
    });

    const { optionsCommune, bDisableComboCommune } = state;

    React.useEffect(() => {
        axios
            .get(urlGetCommune())
            .then((response) => {
                let data = response.data;

                let options = [] as IOptions[];
                if (data.success !== false) {
                    options = response.data?.map((localite: ICommune) => ({
                        value: localite.id_localite,
                        label: localite.nom,
                    }));
                }

                setState({
                    optionsCommune: options,
                    bDisableComboCommune: false,
                });
            })
            .catch((error) => {
                setState((prevState) => ({
                    ...prevState,
                    bDisableComboCommune: false,
                }));

                console.log(error);
            });
    }, []);

    const onChangeCommune = (value: SingleValue<IOptions>) => {
        const idLocalite = value ? value.value : null;

        setExportFilter({
            type: "localite",
            filter: value ? value.value : null,
        });

        if (idLocalite) {
            zoom({
                table: "localite",
                id_field: "id_localite",
                id_value: idLocalite.toString(),
            });
        }

        //@ts-ignore
        mapObj?._draw?.deleteAll();
        //@ts-ignore
        mapObj?._draw?.changeMode("simple_select");
    };

    const getIdCommune = () => {
        const { type, filter } = exportFilter;
        return type == "localite" ? filter : null;
    };

    const onFormatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileFormat(e.target.value);
    };

    const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataType(e.target.value);
    };

    const zoom = (options: zoomOptions) => {
        axios
            .get(urlGetExtent(options))
            .then((response) => {
                if (response?.data?.minx) {
                    let data = response.data;
                    setMapBounds([
                        [data.minx, data.miny],
                        [data.maxx, data.maxy],
                    ]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="tw-mt-4 tw-p-2 ">
            <Select
                placeholder="Localité"
                className="tw-w-[100%] "
                options={optionsCommune}
                value={optionsCommune.filter(
                    (option) => option.value == getIdCommune()
                )}
                onChange={onChangeCommune}
                isClearable={true}
                isDisabled={bDisableComboCommune}
            />
            <div className="tw-flex tw-space-x-6 tw-mt-2">
                <label>Format de sortie :</label>
                <div className="">
                    <label>
                        <input
                            type="radio"
                            value="shape"
                            checked={fileFormat == "shape"}
                            onChange={onFormatChange}
                        />
                        <span className="tw-pl-2">Shape</span>
                    </label>
                    <label className="tw-pl-4">
                        <input
                            type="radio"
                            value="excel"
                            checked={fileFormat == "excel"}
                            onChange={onFormatChange}
                        />
                        <span className="tw-pl-2">Excel</span>
                    </label>
                </div>
            </div>
            <div className="tw-flex tw-space-x-6">
                <label>Type de données :</label>
                <div className="">
                    <label>
                        <input
                            type="radio"
                            value="voie"
                            checked={dataType == "voie"}
                            onChange={onTypeChange}
                        />
                        <span className="tw-pl-2">Voie</span>
                    </label>
                    <label className="tw-pl-4">
                        <input
                            type="radio"
                            value="adresse"
                            checked={dataType == "adresse"}
                            onChange={onTypeChange}
                        />
                        <span className="tw-pl-2">Adresse</span>
                    </label>
                </div>
            </div>
        </div>
    );
}
