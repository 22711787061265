import React from "react";

import Layout from "../layouts/layout";
import SpaceProComponent from "../components/spacepro";


const Spacepro = () => {
    return (
        <Layout>
            <SpaceProComponent />
        </Layout>
    );
};

export default Spacepro;
