import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import File from "../../file";
import { isConnected } from "../../../utils/utils";
import { useSpaceproStore } from "../../../store/spacepro";

export default function Gestionnaire() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "spacePro-gestionnaire" } }
                        page: { code: { _eq: "espacePro" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const { setProfil } = useSpaceproStore();

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }
    const bConnected = isConnected();

    return (
        <div
            className="tw-block sm:tw-grid tw-h-[430px]"
            style={{ gridTemplateColumns: "2fr 1fr" }}
        >
            <div className="tw-p-3 tw-w-full sm:tw-w-7full tw-flex tw-flex-col tw-justify-center tw-bg-[#21254C] tw-bg-opacity-80">
                <div
                    className="tw-p-5 tw-text-white"
                    dangerouslySetInnerHTML={{ __html: content?.text }}
                ></div>
                <div className="tw-flex tw-justify-end tw-px-5 tw-pb-2">
                    {!bConnected && (
                        <button
                            onClick={() => setProfil("gestionnaire")}
                            className="tw-btn tw-border-0 tw-btn-sm tw-font-normal tw-mt-8 tw-w-40 lg:tw-px-4 tw-rounded-xl tw-w-50 tw-text-sm tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                        >
                            <Link
                                to="/connexion"
                                className="tw-no-underline hover:tw-no-underline tw-text-white hover:tw-text-white"
                            >
                                Connexion
                            </Link>
                        </button>
                    )}
                </div>
            </div>
            <div className="tw-hidden sm:tw-w-auto sm:tw-flex tw-justify-center tw-items-center tw-h-[430px] tw-bg-[#21254C] tw-bg-opacity-50">
                <File
                    filename={content?.file?.imageFile?.publicURL}
                    className="tw-h-[430px]"
                />
            </div>
        </div>
    );
}
