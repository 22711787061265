import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import File from "../../file";
import { isConnected } from "../../../utils/utils";
import { useSpaceproStore } from "../../../store/spacepro";

export default function Partenaire() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "spacePro-partenaire" } }
                        page: { code: { _eq: "espacePro" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const { setProfil } = useSpaceproStore();

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }
    const bConnected = isConnected();

    return (
        <div
            className="tw-block sm:tw-grid tw-h-[430px]"
            style={{ gridTemplateColumns: "2fr 1fr" }}
        >
            <div
                className="tw-p-3 tw-w-full sm:tw-w-full tw-flex tw-justify-center tw-items-center tw-h-[430px]"
                style={{ backgroundColor: "#fff" }}
            >
                <File
                    filename={content?.file?.imageFile?.publicURL}
                    className="tw-h-[430px]"
                />
            </div>
            <div
                className="tw-w-full tw-px-3 sm:tw-w-auto tw-p-3 sm:tw-flex tw-justify-center tw-items-center"
                style={{ backgroundColor: "#fff" }}
            >
                <div className="tw-p-5">
                    <div
                        className="tw-p-5 custom-content"
                        dangerouslySetInnerHTML={{ __html: content?.text }}
                    ></div>
                    <div className="tw-flex tw-justify-end tw-px-5 tw-pb-2">
                        {!bConnected && (
                            <button
                                onClick={() => setProfil("partenaire")}
                                className="tw-btn tw-border-0  tw-btn-sm tw-font-normal tw-mt-8 tw-w-40 lg:tw-px-4 tw-rounded-xl tw-w-50 tw-text-sm tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                            >
                                <Link
                                    to="/connexion"
                                    className="tw-no-underline hover:tw-no-underline tw-text-white hover:tw-text-white"
                                >
                                    Connexion
                                </Link>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
