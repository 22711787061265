import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { isConnected } from "../../../utils/utils";
import { useSpaceproStore } from "../../../store/spacepro";

export default function EnSavPlus() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "spacePro-en-sav-plus" } }
                        page: { code: { _eq: "espacePro" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const { setProfil } = useSpaceproStore();

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    const bConnected = isConnected();

    return (
        <div className="tw-w-full tw-px-3 sm:tw-w-4/12 tw-p-3 tw-flex tw-flex-wrap  tw-justify-center ">
            <div className="tw-p-5 ">
                <div
                    className="tw-p-5 custom-content tw-max-w-lg"
                    dangerouslySetInnerHTML={{ __html: content?.text }}
                ></div>
                <div className="tw-flex tw-justify-center tw-pb-2">
                    {!bConnected && (
                        <button
                            onClick={() => setProfil("entreprise")}
                            className="tw-btn tw-btn-sm tw-border-0  tw-font-normal tw-mt-8 tw-w-40 lg:tw-px-4 tw-rounded-xl tw-w-50 tw-text-sm tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                        >
                            <Link
                                to="/connexion"
                                className="tw-no-underline hover:tw-no-underline tw-text-white hover:tw-text-white"
                            >
                                Connexion
                            </Link>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
