import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import File from "../../file";
import { useSpaceproStore } from "../../../store/spacepro";
import { isConnected } from "../../../utils/utils";
import { toast } from "react-toastify";

export default function ExportVoie() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "spacePro-export-voies" } }
                        page: { code: { _eq: "espacePro" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const { setShowViewExport } = useSpaceproStore();

    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    const bConnected = isConnected();

    const onAccessExport = () => {
        let bShowViewExport = false;
        if (bConnected) {
            bShowViewExport = true;
        }
        setShowViewExport(bShowViewExport);

        if (!bShowViewExport) {
            toast.info("Veuillez vous connecter.", {
                position: "top-right",
                autoClose: 1000,
            });
        }
    };

    return (
        <div
            className="tw-block sm:tw-grid tw-h-[430px]"
            style={{ gridTemplateColumns: "2fr 1fr" }}
        >
            <div
                className="tw-p-3 tw-w-full sm:tw-w-full tw-flex tw-flex-col tw-justify-center"
                style={{ backgroundColor: "#46965E" }}
            >
                <div className="tw-block tw-ml-auto tw-mr-auto tw-p-5 tw-text-white">
                    <p
                        className="tw-text-md sm:tw-text-2xl tw-font-bold tw-pb-2 tw-text-center"
                        dangerouslySetInnerHTML={{ __html: content?.text }}
                    ></p>
                    <button
                        className="tw-btn tw-border-0 tw-btn-sm tw-font-normal tw-mt-4 tw-rounded-xl tw-w-50 tw-text-sm tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                        onClick={onAccessExport}
                    >
                        Accéder à l'outil d'export
                    </button>
                </div>
            </div>
            <div
                className="tw-hidden sm:tw-w-[auto] sm:tw-flex tw-justify-center tw-items-center tw-relative tw-h-[430px]"
                style={{ backgroundColor: "#46965E" }}
            >
                <File
                    filename={content?.file?.imageFile?.publicURL}
                    className="tw-h-[430px]"
                />
            </div>
        </div>
    );
}
