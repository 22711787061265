import React from "react";

import ExportVoie from "./directus/exportVoie";
import SpaceproService from "./directus/services";
import EnSavPlus from "./directus/enSavPlus";
import Gestionnaire from "./directus/gestionnaire";
import Partenaire from "./directus/partenaire";
import UploadFile from "./directus/uploadFile";

export default function SpaceProVisitor() {
    return (
        <div>
            <ExportVoie />

            <div className="tw-flex tw-flex-wrap tw-bg-white tw-h-[430px]">
                <SpaceproService />

                <EnSavPlus />
            </div>

            {/* <EnSavPlus /> */}

            <Gestionnaire />

            <Partenaire />

            <UploadFile />
        </div>
    );
}
