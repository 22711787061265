import React from "react";

import { toast } from "react-toastify";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";

import VoieExportForm from "./voieexport/form";
import ExportResume from "./voieexport/resume";

import { useSpaceproStore } from "../../../store/spacepro";
import { urlExportVoie } from "../../../api/api";

export default function SpaceproVoieExport() {
    const [fileFormat, setFileFormat] = React.useState("shape");

    const {
        exportFilter,
        aExportResume,
        setExportFilter,
        setExportResume,
        mapObj,
        setShowViewExport,
        dataType,
    } = useSpaceproStore();

    const onExport = () => {
        const { type, filter } = exportFilter;
        let params: any;

        if (type == "geometry") {
            params = {
                geometry: filter,
            };
        } else {
            params = {
                id_localite: filter,
            };
        }

        if (!aExportResume) {
            toast.info("Aucunes données à exporter.", {
                position: "top-right",
                autoClose: 1000,
            });
            return;
        }

        const link = document.createElement("a");
        link.href = urlExportVoie(params, fileFormat, dataType);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setExportFilter({ type: null, filter: null });
        setExportResume(null);

        //@ts-ignore
        mapObj?._draw?.deleteAll();
    };

    const onGoToSpaceProSection = () => {
        setShowViewExport(false);
    };

    return (
        <>
            <button
                aria-label="Page d'actualités"
                className="tw-flex flex-inline-col  hover:tw-text-gray-500 tw-pb-2"
                onClick={onGoToSpaceProSection}
            >
                <ArrowCircleLeftIcon
                    className="tw-h-6 tw-w-6 tw-mr-2"
                    aria-hidden="true"
                />
                <span className="tw-underline">Retour</span>
            </button>
            <div className="tw-text-center tw-font-bold">Outil d'export</div>
            <div className="tw-border tw-p-2 tw-rounded-md">
                Veuillez sélectionner une localite ou utiliser l'outil de
                sélection pour sélectionner les voies ou adresses à exporter
            </div>
            <VoieExportForm
                fileFormat={fileFormat}
                setFileFormat={setFileFormat}
            />
            <ExportResume />
            <div className="tw-flex tw-justify-center">
                <button
                    onClick={onExport}
                    className="tw-btn tw-btn-sm tw-mt-2 tw-w-full tw-text-xs sm:tw-w-48 sm:tw-text-sm lg:tw-px-4 tw-rounded-xl tw-text-white tw-font-bold tw-bg-secondary hover:tw-bg-secondary_hover"
                >
                    Exporter
                </button>
            </div>
        </>
    );
}
