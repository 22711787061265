import create from "zustand";

type TypeOpacityProperties = string[] | [];

interface layerInterface {
    id: string;
    name: string;
    visible: boolean;
    opacity: number;
    opacityProperties: TypeOpacityProperties;
    children: { id: string; opacityProperties: TypeOpacityProperties }[] | [];
}

interface IExportFilter {
    type: string | null;
    filter: number | [number, number][] | null;
}

interface IVoieExportResume {
    total: number;
    typologie: string;
}

interface IAdressExportResume {
    total: number;
}

type mapBoundsType = [[number, number], [number, number]];
interface ISpaceproStore {
    mapObj: maplibregl.Map | null;
    aExportResume: IAdressExportResume[] | IVoieExportResume[] | null;
    exportFilter: IExportFilter;
    bShowViewExport: boolean;
    bShowViewUploadFile: boolean;
    dataType: string;
    aSelectedAddresses: string[] | [];
    aSelectedVoie: string[] | [];
    layers: layerInterface[];
    mapBounds: mapBoundsType | null;
    profil: string;
    setProfil: (profil: string) => void;
    setMapBounds: (bounds: mapBoundsType) => void;
    setLayers: (layers: layerInterface[]) => void;
    setSelectedAddresses: (aSelectedAddresses: string[] | []) => void;
    setSelectedVoie: (aSelectedVoie: string[] | []) => void;
    setDataType: (dataType: string) => void;
    setMapObj: (mapObj: maplibregl.Map | null) => void;
    setExportResume: (
        aExportResume: IAdressExportResume[] | IVoieExportResume[] | null
    ) => void;
    setExportFilter: (exportFilter: IExportFilter) => void;
    resetStore: () => void;
    setShowViewExport: (bShowViewExport: boolean) => void;
    setShowViewUploadFile: (bShowViewUploadFile: boolean) => void;
}

export const useSpaceproStore = create<ISpaceproStore>((set, get) => ({
    mapObj: null,
    aExportResume: null,
    exportFilter: { type: null, filter: null },
    bShowViewExport: false,
    bShowViewUploadFile: false,
    dataType: "voie",
    aSelectedAddresses: [],
    aSelectedVoie: [],
    layers: [],
    mapBounds: null,
    profil: "DEFAULT",
    setProfil: (profil) => set((state) => ({ ...state, profil })),
    setMapBounds: (bounds) => set((state) => ({ ...state, mapBounds: bounds })),
    setLayers: (layers) =>
        set((state) => {
            if (state.layers.length) {
                return state;
            }

            return { ...state, layers };
        }),
    setSelectedAddresses: (aSelectedAddresses) =>
        set((state) => ({ ...state, aSelectedAddresses })),
    setSelectedVoie: (aSelectedVoie) =>
        set((state) => ({ ...state, aSelectedVoie })),
    setDataType: (dataType) => set((state) => ({ ...state, dataType })),
    setMapObj: (mapObj) => set((state) => ({ ...state, mapObj })),
    setExportResume: (aExportResume) =>
        set((state) => ({ ...state, aExportResume })),
    setExportFilter: (exportFilter) =>
        set((state) => ({ ...state, exportFilter })),
    resetStore: () =>
        set((state) => ({
            ...state,
            mapObj: null,
            aVoieExportResume: null,
            exportFilter: { type: null, filter: null },
            aSelectedAddresses: [],
            aSelectedVoie: [],
        })),
    setShowViewExport: (bShowViewExport) =>
        set((state) => ({ ...state, bShowViewExport })),
    setShowViewUploadFile: (bShowViewUploadFile) =>
        set((state) => ({ ...state, bShowViewUploadFile })),
}));
